









































































































// Core
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { User, UsersState } from '@store/users/interfaces'
import { UsersActions } from '@store/users/types'

const NSUsers = namespace('usersModule')

@Component({
  name: 'User',

  components: {
    'v-project-card': () => import('./components/ProjectCard.vue'),
    'v-dialog-change-password': () => import('@components/dialogs/DialogChangePassword.vue'),
    'v-dialog-select-project': () => import('./components/DialogSelectProject.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})
export default class UserComponent extends Vue {
  @NSUsers.State((state: UsersState) => state.user)
  private user!: User

  @NSUsers.Action(UsersActions.A_GET_USER)
  private fetchUser!: (userId: number) => Promise<User>

  @NSUsers.Action(UsersActions.A_CONNECT_USER_TO_PROJECT)
  private connectUserToProject!: (payload: { userId: number; projectId: number; roleId: number }) => Promise<void>

  @NSUsers.Action(UsersActions.A_DISCONNECT_USER_TO_PROJECT)
  private disconnectUserToProject!: (payload: { userId: number; projectId: number; roleId: number }) => Promise<void>

  private visibleDialogChangePassword = false
  private visibleDialogSelectProject = false

  private userId
  private roleId: number | null = null
  private loading : boolean = false

  created() {
    this.userId = parseInt(this.$route.params.userId, 10)
    this.updateUser()
  }

  private handleConnectUserToProject(projectId: number) {
    return this.connectUserToProject({ projectId, userId: this.userId, roleId: this.roleId as number })
      .then(() => {
        this.$successNotify()
        this.visibleDialogSelectProject = false
        this.updateUser()
      })
      .catch(error => this.$errorNotify(error?.response?.data?.message))
  }

  private handleDisconnectUserToProject(projectId: number) {
    this.loading = true
    this.disconnectUserToProject({ projectId, userId: this.userId, roleId: this.roleId as number })
      .then(() => {
        this.$successNotify()
        return this.updateUser()
      })
      .catch(error => this.$errorNotify(error?.response?.data?.message))
      .finally(() => this.loading = false)
  }

  private async updateUser(){
    this.loading = true
    await this.fetchUser(this.userId)
    this.loading = false
  }
}
